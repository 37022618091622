:root {
	--main_Background_Color: #bebebe;
	--main_Background_Color: #50525c;
	--main_Background_Color_hover: #000087;
	--main_Background_Color_active:#000042;
}

* {
	font-family: 'IBM Plex Arabic';
	color: #131392;
	font-weight: bold;
}

body,
html {
	margin: 0;
	padding: 0;
}


@keyframes delayAppearance {
	0% {
		opacity: 0;
	}

	50% {
		opacity: .5;
	}

	100% {
		opacity: 1;
	}
}