/* import  Background.png from Common/img*/
.main_background {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-image: url('../Img/Background.png');
  --primary-color: rgb(0, 26, 78);
  background-color: var(--primary-color);
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  @media screen and (max-width: 950px) {
    height: 100dvh;
  }
}
