.ensure_title,
.done_title,
.sending_title {
    font-size: 20px;
    font-weight: bold;
    margin: 2vh 1vw;
    padding: 0px;
    text-align: center;
    color: red;
}

.ensure_title {
    color: red;
}

.sending_title,
.done_title {
    color: var(--main_Background_Color_active);
}