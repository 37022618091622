.Qestion {
  display: grid;
  grid-template-columns: 49vw 49vw;
  gap: 2vh;
  justify-items: center;
  align-items: center;
  justify-content: space-around;
  align-content: center;

  @media screen and (max-width: 950px) {
    grid-template-columns: 100vw;
    grid-template-rows: 49dvh 49dvh;
    gap: 2vh;
  }
}

.Qestion_title {
  font-size: 20px;
  font-weight: bold;
  margin: 2vh 1vw;
  padding: 0px;
  text-align: center;
}

.Qestion>.choices {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  animation: delayAppearance .4s linear forwards;
}

.Qestion>.choices>p {
  background-color: var(--main_Background_Color);
  border-radius: 1vh;
  height: 5vh;
  width: 90%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.Qestion>.choices>p.selected,
.Qestion>.choices>p:hover {
  background-color: var(--main_Background_Color_hover);
}

.Qestion>.choices>p:active {
  background-color: var(--main_Background_Color_active);
  width: 85%;
}