.Circle_container {
  margin: auto;
  --Arrow_length: 5vmin;
  --border_width: 1vmin;
  position: relative;
  box-sizing: border-box;
  max-height: calc(70vmin - var(--Arrow_length));
  max-width: calc(45vw - var(--Arrow_length));
  aspect-ratio: 1 / 1;
  z-index: 2;
  opacity: 0;
  animation: delayAppearance .4s linear forwards;

  @media screen and (max-width: 950px) {
    max-width: 95vw;
    max-height: calc(50dvh - var(--Arrow_length));
  }
}



.Circle {
  border-radius: 50%;
  border: var(--border_width) solid white;
  aspect-ratio: 1 / 1;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 2vmin;
}

.Circle>.sub-circle {
  background-color: white;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
  padding: 15% 4%;
  box-sizing: border-box;
  flex-direction: column;
  min-width: 25vw;

  @media screen and (max-width: 950px) {
    min-width: 50vw;
  }
}

.Circle:has(> .loading) {
  border: 0;
}

.Circle>.loading {
  border-radius: 50%;
  border: var(--border_width) solid white;
  border-top: 0;
  /* border-right: 0; */
  border-left: 0;
  aspect-ratio: 1 / 1;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  animation: loading 6s infinite;
  animation-timing-function: linear;

}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}