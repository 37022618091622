.login {
  width: 100vw;
  display: grid;
  grid-template-columns: 49dvh 49dvh;
  gap: 2vh;
  justify-items: stretch;
  align-items: center;
  justify-content: space-around;
  align-content: center;
  margin: 1vh 1vw;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-template-rows: 49dvh 49dvh;
    gap: 2vh;
  }
}

.login_title>p,
.error>p {
  font-size: 25px;
  font-weight: bold;
  margin: 2vh 1vw;
  padding: 0px;
  text-align: center;
}

.error>p {
  color: red;
}




.LoginForm {
  display: flex;
  flex-direction: column;
  margin-left: var(--LoginForm-margin-left);
  margin-right: var(--LoginForm-margin-right);
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.LoginForm>* {
  background-color: var(--main_Background_Color);
  border-radius: 1vh;
  font-size: 25px;
  width: 90%;
  box-sizing: border-box;
  color: white;
}

.LoginForm>button,
.LoginForm>input::placeholder {
  color: white;
  font-weight: bold;
  font-size: 25px;
}

/* select input type number */
.LoginForm>input {}

.LoginForm>button {
  cursor: pointer;
}