.next,
.previous,
.ensureBoutton {
    position: absolute;
    cursor: pointer;
    width: 20%;
    border-radius: 2vh;
    background-color: white;
    aspect-ratio: 1 / 1;
    top: 90%;
}

.next:hover,
.ensureBoutton:hover,
.previous:hover {
    background-color: var(--main_Background_Color_hover);
}

.next:active,
.ensureBoutton:active,
.previous:active {
    background-color: var(--main_Background_Color_active);
    width: 19%;
}

.next,
.ensureBoutton {
    right: 0;
}

.ensureBoutton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bolder;
    cursor: pointer;
    text-align: center;
}

.previous {
    left: 0;
    transform: rotate(180deg);
}