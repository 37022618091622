.Arrow {
  background-color: white;
  width: var(--Arrow_length);
  height: var(--border_width);
  position: absolute;
  top: 50%;
  left: calc(var(--Arrow_length) * -1);

  @media screen and (max-width: 950px) {
    width: var(--border_width);
    height: var(--Arrow_length);
    left: 50%;
    top: 100%;
  }
}

.Arrow.forceDown {
  width: var(--border_width);
  height: var(--Arrow_length);
  left: 50%;
  top: 100%;
}

.Arrow>.Arrowhead {
  width: 0;
  height: 0;
  border-top: calc(var(--border_width) * 2) solid transparent;
  border-bottom: calc(var(--border_width) * 2) solid transparent;
  border-right: calc(var(--border_width) * 4) solid white;
  position: relative;
  top: calc(var(--border_width) * -1.5);
  left: -100%;

  @media screen and (max-width: 950px) {
    border-left: calc(var(--border_width) * 2) solid transparent;
    border-right: calc(var(--border_width) * 2) solid transparent;
    border-top: calc(var(--border_width) * 4) solid white;
    left: calc(var(--border_width) * 1.5);
    top: 100%;
  }
}


.Arrow.forceDown>.Arrowhead {
  border-left: calc(var(--border_width) * 2) solid transparent;
  border-right: calc(var(--border_width) * 2) solid transparent;
  border-top: calc(var(--border_width) * 4) solid white;
  left: calc(var(--border_width) * 1.5);
  top: 100%;
}